<template>
  <div>
    <div class="item-container">
      <div class="item-left">
        <img
          class="img-item"
          :src="image"
          alt=""
          @click="toggleLightBox(image)"
        />
        <div class="inner-item">
          <div class="item-info">
            <p class="item-name">{{ item.name }}</p>
            <del class="item-old-price" v-if="item.item_prices">
              ${{ item.item_prices }}
            </del>
            <p class="item-price">${{ item.price }}</p>
          </div>
          <div class="item-description">
            {{ item.description || "" }}
          </div>
        </div>
      </div>

      <div class="counter-container">
        <div class="counter" :class="{ error: error }">
          <span class="minus" @click="decrementItem(item)">
            <FontAwesomeIcon icon="minus" size="sm" />
          </span>
          <div class="number">{{ getCartItemQuantity(item) }}</div>
          <span class="plus" @click="incrementItem(item)">
            <FontAwesomeIcon icon="plus" size="sm" />
          </span>
        </div>
        <span class="error" v-if="error">{{ error }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { mapState } from "vuex";

library.add(faMinus, faPlus);

const EVENTPAGE = "EVENTPAGE";
const EMBED = "EMBED";
const PLANNER = "PLANNER";

export default {
  name: "guestlist-item",
  components: {
    FontAwesomeIcon
  },
  props: {
    item: Object,
    formType: { type: String, default: PLANNER }
  },
  data() {
    return {
      lightBoxImg: "",
      error: null
    };
  },
  computed: {
    gCart() {
      // if (this.formType === "PLANNER") {
      //   return [];
      // } else {
      //   return this.$store.state.guestlistCart.guestlist_cart;
      // }
    },
    image() {
      if (this.item.image_url) {
        return process.env.VUE_APP_IMAGE_URL + this.item.image_url;
      }
      switch (this.item.type) {
        case "sections":
          return "/img/placeholders/noImageSection.jpg";
        case "bottles":
          return "/img/placeholders/noImageBottle.jpg";
        case "foods":
          return "/img/placeholders/noImageFood.jpg";
        default:
          return "/img/placeholders/noImageBlack.jpg";
      }
    },
    quantity() {
      return this.item
        ? this.getCartItemQuantity(this.item)
        : (this.item.quantity = 0);
    },
    eventUrl() {
      return this.$route.params.event;
    },
    ...mapState(["guestlistCart"])
  },
  methods: {
    incrementItem(item) {
      this.$axios
        .post("/guestlist-buyer/check-max-available", {
          guestlist_item: item,
          quantity: item.quantity + 1
        })
        .then(({ data }) => {
          if (data.success) {
            item.quantity++;
            this.$store.dispatch("guestlistCart/updateCart", {
              id: item._id,
              quantity: item.quantity,
              price: Number(item.price) + Number(item.addfee),
              type: item.type,
              event_url: item.event_url
            });
          }
        })
        .catch(err => {
          if (err) {
            this.error = err.response.data;
            item.quantity = parseInt(this.error.match(/\d+/)[0]); // "3"
            return;
          }
        });
    },
    decrementItem(item) {
      if (item.quantity > 1) {
        item.quantity--;
        this.error = null;
        this.$store.dispatch("guestlistCart/updateCart", {
          id: item._id,
          price: Number(item.price) + Number(item.addfee),
          quantity: item.quantity,
          type: item.type,
          event_url: item.event_url
        });
      } else {
        item.quantity = 0;
        this.$store.dispatch("guestlistCart/removeFromCart", {
          id: item._id
        });
      }
    },
    getCartItemQuantity(item) {
      let foundItem = this.$store.state.guestlistCart.guestlist_cart.find(
        gc => {
          return gc.id === item._id;
        }
      );

      return foundItem ? foundItem.quantity : 0;
    },
    toggleLightBox(val, index) {
      if (this.formType === EVENTPAGE) {
        if (val == "close") {
          document.getElementById("light-box").style.display = "none";

          let body = document.getElementsByTagName("body");
          body[0].style.height = "100%";
          body[0].style.overflow = "visible";
        } else {
          document.getElementById("light-box").style.display = "flex";
          let body = document.getElementsByTagName("body");
          body[0].style.height = "100%";
          // body[0].style.overflow = "hidden";
          // window.scroll(0, 0);

          document.getElementById("image").src = val;
          // this.lightBoxImg = val;
          // this.imgIndex = index;
        }
      }
    }
    // incrementItem(tier, index, venueAccessDate, venueAccessTime) {
    //   const eventURL = this.$route.params.event;
    //   const cart = this.$store.state.guestlist_cart;
    //   const item = getCartItem(cart, tier, venueAccessDate, venueAccessTime);
    //   const ccfee = tier.absorb ? 0 : tier.ccfee;
    //   const dfee = tier.absorb ? 0 : tier.dfee;
    //   let name = tier.name;
    //   if (venueAccessTime) {
    //     name +=
    //       " " + dayjs(venueAccessTime.start).format("(MMM DD, YYYY h:mm A)");
    //   } else if (venueAccessDate) {
    //     name += " " + dayjs(venueAccessDate.start).format("(MMM DD, YYYY)");
    //   }
    //   this.updateCart(item._id, item.quantity);
    //   // let limit = tierSaleDate.purchase_limit
    //   //   ? tierSaleDate.max_purchase
    //   //   : getMaxQuantity(tier, venueAccessDate, venueAccessTime);
    //   this.$forceUpdate();
    // },
    // decrementItem(tier, index, venueAccessDate, venueAccessTime) {
    //   const cart = this.$store.state.guestlist_cart;
    //   const item = getCartItem(cart, tier, venueAccessDate, venueAccessTime);
    //   let tierSaleDate = tier.saleDates.find(x => tier.saleDateId === x.id);
    //   let min_purchase = tierSaleDate.purchase_limit
    //     ? tierSaleDate.min_purchase
    //     : 1;
    //   this.inputArr[index] =
    //     parseInt(this.inputArr[index]) - min_purchase < min_purchase
    //       ? 0
    //       : parseInt(this.inputArr[index]) - min_purchase;
    //   if (item) {
    //     item.quantity =
    //       item.quantity - min_purchase < min_purchase
    //         ? 0
    //         : item.quantity - min_purchase;
    //     if (item.quantity <= 0) {
    //       cart.splice(cart.indexOf(item), 1);
    //     }
    //     this.updateCart(item._id, item.quantity);
    //   }
    //   this.$forceUpdate();
    // },
    // updateCart(productId, quantity) {
    //   this.$store
    //     .dispatch("updateCart", { productId, quantity })
    //     .then(() => {
    //       console.log("Cart updated successfully");
    //     })
    //     .catch(error => {
    //       console.error(error);
    //     });
    // }
  }
};
</script>

<style lang="less" scoped>
.item-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 16px 0;

  .item-left {
    display: flex;
    column-gap: 16px;
    align-items: center;
    margin-right: 16px;

    p {
      margin: 0;
    }
    .item-name,
    .item-price,
    .item-old-price {
      font-weight: bold;
      font-size: 16px;
    }
    .item-info {
      display: flex;
      grid-column-gap: 16px;
      -moz-column-gap: 16px;
      column-gap: 16px;
      align-items: center;
      margin-right: 16px;
    }
    .item-old-price {
      color: red;
      font-weight: bold;
    }
    .item-price {
      font-weight: bold;
      color: var(--primary);
    }
    .item-description {
      font-size: 12px;
    }
  }

  .img-item {
    width: 70px;
    height: 70px;
    cursor: pointer;
    border-radius: 8px;
    object-fit: contain;
    border: 1px solid var(--input-border);
    transition: 0.5s all;

    &:hover {
      width: 75px;
      height: 75px;
    }
  }

  .counter {
    display: flex;
    align-items: center;
    padding: 2px 0;
    font-size: 21px;
    background: var(--input-background);
    color: var(--text);
    border: 2px solid var(--input-border);
    border-radius: 8px;
    height: fit-content;

    .minus,
    .plus {
      margin: 0 10px;
      cursor: pointer;
      font-family: inherit;

      outline: none;
      -webkit-appearance: none;
      transition: all 0.4s ease, visibility 0s;
      -webkit-user-select: none;
    }

    .number {
      width: 18px;
      font-weight: bold;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--text);
      border-radius: 4px;
      -moz-appearance: none;
      appearance: none;
      -webkit-appearance: none;
      outline: none;
      background: var(--input-background);

      &:focus {
        color: var(--text);
        border: 1px solid #48bb8b;
        background-color: var(--active-background);
      }
    }

    &.error {
      border-color: red !important;
    }
  }

  .counter-container {
    span.error {
      color: red;
    }
  }
}
</style>