<template>
  <div>
    <add-to-list :url="url" formType="PLANNER" />
  </div>
</template>

<script>
import AddToList from "@/components/GuestlistAddForm.vue";

export default {
  name: "guestlist-add",
  components: {
    AddToList
  },
  props: {
    url: String
  }
};
</script>
