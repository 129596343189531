<template>
  <div class="add-to-list-container">
    <div>
      <div v-if="showPreviewLink" class="row">
        <p class="preview">Preview Only</p>
        <a :href="embedURL" target="_blank" class="link" v-if="embedURL">
          <font-awesome-icon :icon="['fas', 'link']" class="link-icon" />
          Link
        </a>
      </div>
      <h1 v-if="embedPage || embedSettings || eventPage">Book a Guest List</h1>
      <h2 v-if="eventDate && !eventPage && !embedSettings">
        {{ eventName }} - {{ formattedEventDate }}
      </h2>
      <loader site="buyers" :active="loading" />

      <FormulateForm
        id="guestlist-add-form"
        ref="guestlistForm"
        @submit="validateForm"
        name="guestlist"
      >
        <div class="row" v-if="!loading">
          <div class="col full">
            <diyobo-input
              v-if="embedPage || embedSettings"
              type="dropdown"
              label="Select Event Date"
              placeholder="Select Event Date"
              required
              :options="eventsForDropdown"
              v-model="formValues.event"
              :val="formValues.event"
              @input="onSelectEvent"
            />
          </div>

          <template v-if="formValues.event || url">
            <div class="closed-wrapper row" v-if="closed">
              <div class="col full flex center">
                <h4>
                  Guest List is Now Closed.
                </h4>
              </div>
            </div>

            <div class="row" v-else>
              <div class="col full center">
                <InlineMessageBox header="General Information">
                  <div class="inline-slot">
                    <ul>
                      <div v-for="(item, index) in details" :key="index">
                        <li>
                          {{ item.value }}
                        </li>
                      </div>
                    </ul>
                  </div>
                </InlineMessageBox>
              </div>
              <div class="col half">
                <FormulateInput
                  type="text"
                  label="First Name"
                  placeholder="First Name"
                  name="firstName"
                  v-model="formValues.firstName"
                  validation="required"
                />
              </div>

              <div class="col half">
                <FormulateInput
                  type="text"
                  label="Last Name"
                  placeholder="Last Name"
                  name="lastName"
                  v-model="formValues.lastName"
                  validation="required"
                />
              </div>

              <div class="col half">
                <FormulateInput
                  type="email"
                  label="Email"
                  placeholder="example@domain.com"
                  name="email"
                  v-model="formValues.email"
                  validation="required|email"
                />
              </div>

              <div class="col half">
                <FormulateInput
                  type="tel"
                  label="Phone Number"
                  placeholder="(204) 222-2222"
                  name="phone"
                  v-model="formValues.phone"
                />
              </div>

              <div class="col half" v-if="dates.length > 0">
                <FormulateInput
                  type="select"
                  name="reservationDate"
                  label="Reservation Date"
                  placeholder="Select From Our Available Dates"
                  :options="dates"
                  v-model="formValues.reservationDate"
                  validation="required"
                />
              </div>

              <div class="col half" v-if="timeSlots.length > 0">
                <FormulateInput
                  type="select"
                  label="Estimate Arrival Time"
                  placeholder="Select Your Arrival Time"
                  name="estArrTime"
                  :options="timeSlots"
                  v-model="formValues.estArrTime"
                  validation="required"
                />
              </div>

              <div class="col half">
                <FormulateInput
                  type="select"
                  label="Occasion"
                  placeholder="Reason for Booking"
                  name="occasion"
                  :options="occasions"
                  v-model="formValues.occasion"
                  validation="required"
                />
              </div>
              <div class="col half">
                <FormulateInput
                  type="date"
                  label="Birthdate"
                  :model-config="dateFormat"
                  :masks="{ L: 'MMMM D, YYYY' }"
                  placeholder="Enter Date"
                  name="birthdate"
                  v-model="formValues.birthdate"
                  :validation="mainSettings.collect_birthdays ? 'required' : ''"
                />
              </div>
              <div
                class="col half"
                v-if="promoterId || promotersAvailable.length"
              >
                <FormulateInput
                  v-if="promoterId"
                  type="text"
                  label="Promoter Referral"
                  placeholder="Search for a promoter"
                  disabled
                  v-model="promoterFromUrl"
                />

                <FormulateInput
                  v-else
                  type="selectSearch"
                  label="Promoter Referral"
                  placeholder="Search for a promoter"
                  :options="promotersAvailable"
                  @input="setPromoter"
                  v-model="promoter"
                />
              </div>

              <div class="col full">
                <div class="est-people-container input-wrapper">
                  <label for="est-people">Estimated Guests</label>
                  <span class="error">{{ size_error }}</span>
                  <div class="input-container">
                    <div class="male-counter">
                      <div class="gender-icon male">
                        <font-awesome-icon
                          :icon="['fas', 'mars']"
                          class="male-icon"
                        />
                      </div>
                      <div class="counter">
                        <span class="minus" @click="minus('male')">
                          <FontAwesomeIcon icon="minus" size="sm" />
                        </span>
                        <input
                          type="number"
                          name=""
                          min="0"
                          id=""
                          class="male-input"
                          v-model="formValues.malePerson"
                          @input="setGuestsQuantity('malePerson', $event)"
                          @keyup="validateAt('malePerson')"
                        />
                        <span class="plus" @click="plus('male')">
                          <FontAwesomeIcon icon="plus" size="sm" />
                        </span>
                      </div>
                    </div>
                    <div class="female-counter">
                      <div class="gender-icon female">
                        <font-awesome-icon
                          :icon="['fas', 'venus']"
                          class="female-icon"
                        />
                      </div>
                      <div class="counter">
                        <span class="minus" @click="minus('female')">
                          <FontAwesomeIcon icon="minus" size="sm" />
                        </span>
                        <input
                          type="number"
                          name=""
                          id=""
                          min="0"
                          class="female-input"
                          v-model="formValues.femalePerson"
                          @input="setGuestsQuantity('femalePerson', $event)"
                          @keyup="validateAt('femalePerson')"
                        />
                        <span class="plus" @click="plus('female')">
                          <FontAwesomeIcon icon="plus" size="sm" />
                        </span>
                      </div>
                    </div>
                    <div class="non-specific-counter">
                      <div class="gender-icon ns">
                        <font-awesome-icon
                          :icon="['fas', 'genderless']"
                          class="others-icon"
                        />
                      </div>
                      <div class="counter">
                        <span class="minus" @click="minus('ns')">
                          <FontAwesomeIcon icon="minus" size="sm" />
                        </span>
                        <input
                          type="number"
                          name=""
                          id=""
                          min="0"
                          class="non-specific-input"
                          v-model="formValues.nonSpecificPerson"
                          @input="
                            setGuestsQuantity('nonSpecificPerson', $event)
                          "
                          @keyup="validateAt('nonSpecificPerson')"
                        />
                        <span class="plus" @click="plus('ns')">
                          <FontAwesomeIcon icon="plus" size="sm"
                        /></span>
                      </div>
                    </div>
                    <!-- <div class="total-counter">
                  <div class="label">Total Guests</div>
                  <div class="total-container">
                    {{ totalGuests }}
                  </div>
                </div> -->
                  </div>
                </div>
              </div>
              <div class="divider"></div>
              <div class="sections row" v-if="sections.length > 0">
                <div class="col half center flex">
                  <h2>Select Your Sections</h2>
                </div>
                <div class="divider"></div>
                <div class="row">
                  <div
                    class="col half"
                    v-for="(item, index) in sections"
                    :key="index"
                  >
                    <ServiceItem :item="item" :formType="formType" />
                  </div>
                </div>

                <div class="divider"></div>
              </div>

              <div class="row bottles" v-if="bottles.length > 0">
                <div class="row">
                  <div class="col half center flex">
                    <h2>Bottle Service Menu</h2>
                  </div>
                </div>

                <div class="divider"></div>
                <div class="row">
                  <div
                    class="col half"
                    v-for="(item, index) in bottles"
                    :key="index"
                  >
                    <ServiceItem :item="item" :formType="formType" />
                  </div>
                </div>
                <div class="divider"></div>
              </div>

              <div class="row foods" v-if="foods.length > 0">
                <div class="row">
                  <div class="col half center flex">
                    <h2>Food Service Menu</h2>
                  </div>
                </div>

                <div class="divider"></div>
                <div class="row">
                  <div
                    class="col half"
                    v-for="(item, index) in foods"
                    :key="index"
                  >
                    <ServiceItem :item="item" :formType="formType" />
                  </div>
                </div>
                <div class="divider"></div>
              </div>

              <div class="row groups" v-if="groups.length > 0">
                <div class="row">
                  <div class="col half center flex">
                    <h2>Packages</h2>
                  </div>
                </div>

                <div class="divider"></div>
                <div class="row">
                  <div
                    class="col half"
                    v-for="(item, index) in groups"
                    :key="index"
                  >
                    <ServiceItem :item="item" :formType="formType" />
                  </div>
                </div>
                <div class="divider"></div>
              </div>

              <div class="col full">
                <FormulateInput
                  type="textarea"
                  label="Additional Notes"
                  :placeholder="'Please add something here'"
                  name="additionalData"
                  v-model="formValues.additionalData"
                />
              </div>
              <div class="row">
                <div
                  class="order-total col half flex center"
                  v-if="(embedPage || plannerPage) && hasItemsInCart"
                >
                  <h2>Order Total: ${{ total.toFixed(2) }}</h2>
                </div>
                <div class="col quarterx3 flex center fee-statement">
                  <p
                    v-if="embedPage && hasItemsInCart"
                    style="text-align: center;"
                  >
                    Fair fees, taxes and auto-gratuity may apply on check out.
                    Processing fees total shown on purchase summary.
                  </p>
                </div>
              </div>

              <div class="col half flex center guestlist-submit">
                <template v-if="eventUrl">
                  <FormulateInput
                    class="embedpage-checkout"
                    v-if="hasCheckout"
                    :disabled="!hasItemsInCart"
                    type="submit"
                    name="Checkout"
                  />
                  <FormulateInput
                    class="dummy-button"
                    v-else-if="embedSettings"
                    type="button"
                    name="Submit for Approval"
                  />
                  <FormulateInput
                    class="submit-guestlist"
                    v-else-if="!hasItemsInCart && !requirePurchase"
                    type="submit"
                    name="Submit for Approval"
                  />
                </template>
              </div>
            </div>
          </template>

          <div class="footer row">
            <div class="col full">
              <div class="powered-logo" v-if="embedPage || embedSettings">
                <p>
                  <b> Powered by INCREDEVENT </b>
                </p>

                <img src="@/images/logos/IncredEvent-Mobile-Dark.webp" alt="" />
              </div>
            </div>
          </div>
        </div>
      </FormulateForm>
    </div>
  </div>
</template>

<script>
import DiyoboInput from "@/components/DiyoboInput.vue";
import DiyoboButton from "@/components/DiyoboButton.vue";
import { formatPhoneNumber } from "@/helpers/input-formats.js";
import * as yup from "yup";
import ModalContent from "@/helpers/modals";
import BulletPointBox from "@/components/BulletPointBox.vue";
import Loader from "@/components/Loader.vue";
import InlineMessageBox from "@/components/InlineMessageBox.vue";

import ServiceItem from "@/components/Guestlist/ServiceItem.vue";
import Sections from "@/components/Guestlist/Sections.vue";

import dateformat from "dateformat";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faTransgender,
  faMinus,
  faPlus,
  faMars,
  faVenus,
  faGenderless,
  faLink
} from "@fortawesome/free-solid-svg-icons";

import { mapState } from "vuex";
import Cookies from "universal-cookie";
import dayjs from "dayjs";
import Timezones from "dayjs/plugin/timezone";
dayjs.extend(Timezones);

library.add(
  faMinus,
  faPlus,
  faTransgender,
  faMars,
  faVenus,
  faGenderless,
  faLink
);

const VALIDATION_SCHEMA = yup.object({
  event: yup.string().required("Event is required."),
  email: yup
    .string()
    .email()
    .required("Email is required."),
  firstName: yup.string().required("First Name is required."),
  lastName: yup.string().required("Last Name is required."),
  // phone: yup.string().required("Phone Number is required."),
  estArrTime: yup.string().required("Est Arrival Time is required."),
  occasion: yup.string().required("Occasion is required."),
  // section: yup.string().required("Section is required."),
  malePerson: yup
    .number()
    .integer("Person must be a whole number.")
    .moreThan(-1, "Person must be a positive number.")
    .transform(value => (isNaN(value) ? undefined : value))
    .required("Male count is required."),
  femalePerson: yup
    .number()
    .integer("Person must be a whole number.")
    .moreThan(-1, "Person must be a positive number.")
    .transform(value => (isNaN(value) ? undefined : value))
    .required("Female count is required."),
  nonSpecificPerson: yup
    .number()
    .integer("Person must be a whole number.")
    .moreThan(-1, "Person must be a positive number.")
    .transform(value => (isNaN(value) ? undefined : value))
    .required("Non-Specific count is required.")
});

const EVENTPAGE = "EVENTPAGE";
const EMBED = "EMBED";
const PLANNER = "PLANNER";

export default {
  name: "add-to-list",

  components: {
    DiyoboInput,
    DiyoboButton,
    FontAwesomeIcon,
    BulletPointBox,
    Loader,
    InlineMessageBox,
    ServiceItem,
    Sections
  },
  props: {
    url: String,
    events: Array,
    embedURL: String,
    redirectIfDisabled: Boolean,
    showPreviewLink: Boolean,
    formType: { type: String, default: EVENTPAGE },

    //List Settings to remove submit functions
    embedSettings: { type: Boolean, default: false }
  },
  data() {
    return {
      timeSlots: [],
      dateFormat: {
        type: "string",
        mask: "MMMM D, YYYY"
      },
      sectionMessage: "",
      occasions: [
        { label: "Birthday", value: "Birthday" },
        {
          label: "Anniversary",
          value: "Anniversary"
        },
        {
          label: "New Job",
          value: "New Job"
        },
        {
          label: "Bachelor(ette)",
          value: "Bachelor(ette)"
        },
        {
          label: "General",
          value: "General"
        }
      ],
      loading: false,
      closed: false,

      tryingCheckout: false,

      sections: [],
      bottles: [],
      foods: [],
      groups: [],
      dates: [],
      promoterFromUrl: "",
      promoter: "",
      promotersAvailable: [],
      promoterIdFromSettings: "",

      details: [],
      eventName: "",
      eventDate: "",
      mainSettings: {},
      size_error: null,
      formValues: this.$store.state.guestlistCart.guestlist,
      formErrors: {
        event: "",
        email: "",
        firstName: "",
        lastName: "",
        estArrTime: "",
        occasion: "",
        additionalData: "",
        malePerson: "",
        femalePerson: "",
        nonSpecificPerson: "",
        section: ""
      }
    };
  },
  watch: {
    url() {
      this.getGuestSettings();
    }
  },
  computed: {
    hasCheckout() {
      if ((this.embedPage || this.plannerPage) && this.hasItemsInCart) {
        return true;
      } else {
        return false;
      }
    },
    total() {
      // Guestlist Total
      let guestlistTotal = this.$store.getters
        .getSelectedGuestlistItems(this.eventUrl)
        .reduce((a, b) => a + b.price * b.quantity, 0);

      return guestlistTotal;
    },
    ...mapState(["guestlistCart"]),
    eventUrl() {
      return this.url || this.formValues.event || this.$route.params.event;
    },
    promoterId() {
      if (this.$route.query.pv) {
        return this.$route.query.pv;
      }
    },

    embedPage() {
      return this.formType === EMBED;
    },
    eventPage() {
      return this.formType === EVENTPAGE;
    },
    plannerPage() {
      return this.formType === PLANNER;
    },

    phoneOutput: {
      get() {
        return this.formValues.phone;
      },
      set(value) {
        this.formValues.phone = formatPhoneNumber(value);
      }
    },
    requirePurchase() {
      return this.mainSettings.require_purchase;
    },
    hasItemsInCart() {
      const guestlist_items = this.$store.getters.getSelectedGuestlistItems(
        this.eventUrl
      );
      return guestlist_items.length > 0;
    },

    totalGuests() {
      let sum =
        parseInt(this.formValues.malePerson) +
        parseInt(this.formValues.femalePerson) +
        parseInt(this.formValues.nonSpecificPerson);
      return sum;
    },
    formattedEventDate() {
      return dateformat(Number(this.eventDate), "ddd, mmm dS, yyyy, h:MM TT");
    },
    eventsForDropdown() {
      if (!this.events) {
        return [];
      }

      return this.events.map(e => ({
        label:
          e.frequency == "SINGLE_DATE"
            ? e.name +
              " (" +
              dateformat(
                e.start.$date ? Number(e.start.$date.$numberLong) : e.start,
                "ddd, mmm dS, yyyy, h:MM TT"
              ) +
              ")"
            : e.name,
        value: e.url
      }));
    }
  },

  methods: {
    tryCheckout() {
      this.tryingCheckout = true;
      this.$formulate.submit("guestlist");
      this.$emit("hasError", this.$refs.guestlistForm.hasErrors);
    },
    removeError() {
      this.tryingCheckout = false;
      this.$emit("hasError", false);
    },
    showMessage(sec, value) {
      let ogSection = this.sections.find(section => {
        return section.value === sec;
      });

      if (ogSection.cash) {
        this.sectionMessage =
          "$ means that the reservation will cost extra and the coordinator will contact you with more details.";
      } else {
        this.sectionMessage = "";
      }
    },
    setPromoter(promoter) {
      if (promoter) {
        this.formValues.pv = promoter.value;
      } else {
        this.formValues.pv = null;
      }
    },
    filterData() {
      this.sections.forEach(element => {
        this.$set(element, "quantity", this.getCartItemQuantity(element));
        this.$set(element, "type", "sections");
        this.$set(element, "event_url", this.eventUrl);
      });
      this.bottles.forEach(element => {
        this.$set(element, "quantity", this.getCartItemQuantity(element));
        this.$set(element, "type", "bottles");
        this.$set(element, "event_url", this.eventUrl);
      });
      this.foods.forEach(element => {
        this.$set(element, "quantity", this.getCartItemQuantity(element));
        this.$set(element, "type", "foods");
        this.$set(element, "event_url", this.eventUrl);
      });
      this.groups.forEach(element => {
        this.$set(element, "quantity", this.getCartItemQuantity(element));
        this.$set(element, "type", "packages");
        this.$set(element, "event_url", this.eventUrl);
      });
    },
    getCartItemQuantity(item) {
      if (this.formType === EVENTPAGE || this.formType === EMBED) {
        let foundItem = this.$store.state.guestlistCart.guestlist_cart.find(
          gc => {
            return gc.id === item._id;
          }
        );

        return foundItem ? foundItem.quantity : 0;
      } else {
        return 0;
      }
    },
    minus(type) {
      this.size_error = null;
      if (type === "male") {
        if (this.formValues.malePerson > 0) {
          this.formValues.malePerson--;
        }
      } else if (type === "female") {
        if (this.formValues.femalePerson > 0) {
          this.formValues.femalePerson--;
        }
      } else {
        if (this.formValues.nonSpecificPerson > 0) {
          this.formValues.nonSpecificPerson--;
        }
      }
      this.$forceUpdate();
    },
    plus(type) {
      let settings = this.mainSettings;
      let quantity =
        this.getGuestsQuantity("malePerson") +
        this.getGuestsQuantity("femalePerson") +
        this.getGuestsQuantity("nonSpecificPerson") +
        1;

      if (quantity > settings.max_group_size) {
        this.size_error = `Minimum Group Size: ${settings.min_group_size} | Maximum Group Size: ${settings.max_group_size}`;
      } else {
        if (type === "male") {
          this.formValues.malePerson = this.getGuestsQuantity("malePerson") + 1;
        } else if (type === "female") {
          this.formValues.femalePerson =
            this.getGuestsQuantity("femalePerson") + 1;
        } else {
          this.formValues.nonSpecificPerson =
            this.getGuestsQuantity("nonSpecificPerson") + 1;
        }
      }
      this.$forceUpdate();
    },
    async validateAt(field) {
      return VALIDATION_SCHEMA.validateAt(field, this.formValues)
        .then(() => {
          this.formErrors[field] = "";
        })
        .catch(err => {
          this.formErrors[field] = err.message;
        });
    },
    async validateForm() {
      if (this.tryingCheckout) {
        return;
      }

      this.formValues.event = this.eventUrl;
      this.formValues.pv = this.promoterIdFromSettings;

      // Add to Guestlist Cart Before Submit
      this.guestlistForm(this.formValues);

      if (this.embedPage || this.plannerPage) {
        if (this.hasItemsInCart) {
          this.submitDataAndPurchase();
        } else {
          this.submitEmbedData();
        }
      } else {
        this.submitData();
      }
    },
    async validateFormForCheckout() {
      this.formValues.event = this.eventUrl;
      if (this.promoterIdFromSettings !== "") {
        this.formValues.pv = this.promoterIdFromSettings;
      }

      // Add to Guestlist Cart Before Submit
      this.guestlistForm(this.formValues);
    },
    getGuestSettings() {
      if (!this.url) {
        return;
      }
      this.loading = true;
      this.$axios
        .post("/guestlist/get-settings", {
          event: this.url,
          promoter: this.promoterId,
          tz: dayjs.tz.guess()
        })
        .then(({ data }) => {
          if (!data.settings && this.formType === PLANNER) {
            this.$router.replace(`/guestlist/${this.url}/list-settings`);
            return;
          }

          if (data.settings.enable) {
            this.eventName = data.event_name;
            this.eventDate = data.event_date;
            this.mainSettings = data.settings;

            if (data.promoter) {
              this.promoterIdFromSettings = data.promoter._id;
              this.promoterFromUrl = data.promoter.name;
            }

            this.promotersAvailable = data.promoters;

            this.sections = data.sections;
            this.bottles = data.bottles;
            this.foods = data.foods;
            this.groups = data.packages;
            this.dates = data.event_dates;

            this.closed = data.close_guestlist || false;
            this.details = data.settings.reservation_details;
            this.timeSlots = data.settings.time_slots;

            if (this.mainSettings.is_max) {
              this.$bus.$emit("alert", ModalContent.guestlistFull);
            }
            this.filterData();
          }

          this.loading = false;
        });
    },
    onSelectEvent(url) {
      const event = this.events.find(e => e.url === url);

      if (event) {
        const settings = event.guestlist_settings;
        this.mainSettings = settings;

        this.details = settings.reservation_details;
        this.timeSlots = settings.time_slots;

        if (event.promoter) {
          this.promoterIdFromSettings = event.promoter._id;
          this.promoterFromUrl = event.promoter.name;
        }

        this.promotersAvailable = event.promoters;

        this.closed =
          this.formType === PLANNER ? false : event.close_guestlist || false;
        this.sections = event.sections;
        this.bottles = event.bottles;
        this.groups = event.packages;
        this.foods = event.foods;
        this.dates = event.dates;

        this.filterData();

        this.formValues.event = url;
        this.formValues.estArrTime = "";
        this.formValues.occasion = "";
        this.formValues.section = "";
        this.formValues.malePerson = 0;
        this.formValues.femalePerson = 0;
        this.formValues.nonSpecificPerson = 0;

        this.$emit("eventsChange");
      }
    },
    getGuestsQuantity(type) {
      return this.formValues[type] ? this.formValues[type] : 0;
    },
    setGuestsQuantity(type, { target }) {
      this.$set(this.formValues, type, parseInt(target.value) || 0);
    },
    guestlistForm(values) {
      this.$store.dispatch("guestlistCart/updateGuestlist", values);
    },
    submitData() {
      this.loading = true;

      this.$axios
        .post("/guestlist/add-guest", {
          guest: this.formValues,
          event: this.formValues.event
        })
        .then(({ data }) => {
          // console.log(data.error.message);
          if (!data.error) {
            this.clearInputs();
            this.$bus.$emit("alert", ModalContent.guestAddedUser);
            this.loading = false;
          } else {
            if (data.error.type === "GuestListFull") {
              this.clearInputs();

              this.$bus.$emit("alert", {
                title: data.error.title,
                msg: data.error.message
              });
            } else {
              this.$bus.$emit("alert", {
                title: "Error",
                msg: data.error.message
              });
            }
            this.loading = false;
          }
        });
    },
    async submitDataAndPurchase() {
      this.loading = true;

      const guestlist_items = this.$store.getters.getSelectedGuestlistItems(
        this.eventUrl
      );
      const guestlist = this.$store.getters.getGuestlistForm(this.eventUrl);

      if (guestlist_items.length > 0) {
        const cookies = new Cookies();
        const cartID = cookies.get(`${this.eventUrl}-cart`);

        try {
          await this.$axios
            .post("/cart/update", {
              guestlist_items,
              guestlist,
              event: this.eventUrl,
              cart: cartID
            })
            .then(response => {
              if (response.status === 200) {
                const data = response.data;
                const cart = data.cart;
                const alert = data.alert;

                const expires = dayjs(+cart.created_at)
                  .add(60, "minutes")
                  .toDate();

                // cookies.remove(`${this.eventUrl}-cart`);

                //if (this.plannerPage) {
                let appEnvironment = process.env.VUE_APP_ENVIRONMENT;
                let domainName = "";
                let name = "";

                switch (appEnvironment) {
                  case "development":
                    domainName = "http://localhost:3100";
                    name = "localhost:3100";
                    break;
                  case "staging":
                    domainName = "http://staging.incredevent.com";
                    name = "staging.incredevent.com";
                    break;
                  default:
                    domainName = "https://incredevent.com";
                    name = "incredevent.com";
                    break;
                }

                cookies.set(`${this.eventUrl}-cart`, cart.id, {
                  domain: `.${name}`,
                  path: "/",
                  secure: true,
                  sameSite: "none",
                  expires
                });
                if (this.plannerPage) {
                  window.location.href = `${domainName}/${this.eventUrl}/checkout/account-information`;
                } else {
                  window.open(
                    `${domainName}/${this.eventUrl}/checkout/account-information`,
                    "_blank"
                  );
                  this.loading = false;
                }
                // } else {
                //   this.$router.open({
                //     path: `/${this.eventUrl}/checkout/account-information`
                //   });
                // }

                if (alert) {
                  this.$bus.$emit("alert", {
                    title: "Note",
                    msg: alert
                  });
                }
              }
            });
        } catch (error) {
          if (error.response) {
            this.$bus.$emit("alert", {
              title: "Error",
              msg:
                error.response.data ||
                "An unknown error occurred. Please try again later."
            });
          }
          this.loading = false;
          return;
        }
      }
    },
    submitEmbedData() {
      this.loading = true;

      this.$axios
        .post("/guestlist/add-guest-embed", {
          guest: this.formValues,
          event: this.formValues.event
        })
        .then(({ data }) => {
          if (!data.error) {
            this.clearInputs();
            this.$bus.$emit("alert", ModalContent.guestAddedUser);
            this.$emit("submitted");
          } else {
            if (data.error.type === "GuestListFull") {
              this.clearInputs();

              this.$bus.$emit("alert", {
                title: data.error.title,
                msg: data.error.message
              });
            } else {
              this.$bus.$emit("alert", {
                title: "Error",
                msg: data.error.message
              });
            }
          }
          this.loading = false;
        });
    },
    clearErrors() {
      this.formErrors = {
        email: "",
        firstName: "",
        lastName: "",
        estArrTime: "",
        occasion: "",
        additionalData: "",
        malePerson: "",
        femalePerson: "",
        nonSpecificPerson: "",
        section: ""
      };
    },
    clearInputs() {
      let event = this.formValues.event;
      this.formValues = {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        estArrTime: "",
        occasion: "",
        birthdate: "",
        section: "",
        additionalData: "",
        malePerson: 0,
        femalePerson: 0,
        nonSpecificPerson: 0,
        event: event,
        pv: null
      };
      this.$refs.guestlistForm.hideErrors();
      this.getGuestSettings();
    }
  },
  created() {
    this.formValues.event = this.url;
    this.getGuestSettings();
  }
};
</script>
<style lang="less" scoped>
.inline-slot {
  ul {
    margin-left: 32px;
  }
}
// .center {
//   text-align: center;
// }
h1 {
  font-size: 30px;
  text-align: center;
  color: var(--custom-text-color);
}

h2 {
  font-size: 24px;
  margin: 8px 0;
  color: var(--custom-text-color);
}
p {
  margin: 0;
}
.col {
  flex-basis: unset !important;
}

.powered-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  color: var(--custom-text-color);
  p {
    font-size: 14px;
    color: var(--custom-text-color);
  }
  img {
    width: 40px;
    height: auto;
    margin: 0 12px;
  }
}
.est-people-container {
  // display: flex;
  text-align: center;

  .minus,
  .plus {
    margin: 0 10px;
    cursor: pointer;
    font-family: inherit;

    outline: none;
    appearance: none;
    -webkit-appearance: none;
    transition: all 0.4s ease, visibility 0s;
    user-select: none;
    -webkit-user-select: none;
  }

  .input-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
  }

  .male-counter,
  .female-counter,
  .non-specific-counter {
    display: flex;
    align-items: center;
    margin-right: 24px;
    // margin-bottom: 12px;
  }

  @media screen and (max-width: 555px) {
    .input-container {
      justify-content: center;
    }
    .male-counter,
    .female-counter,
    .non-specific-counter {
      margin: 10px 0;
    }

    .total-counter {
      margin-left: 40px;
    }
  }
  .counter {
    display: flex;
    align-items: center;
    padding: 10px;
    font-size: 32px;
    background: var(--input-background);
    color: var(--text);
    border: 1px solid var(--input-border);
    border-radius: 8px;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    appearance: none;
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    appearance: none;
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  input[type="number"] {
    appearance: textfield;
    -moz-appearance: textfield; /* Firefox */
  }
  input {
    // height: 34px;
    width: 50px;
    text-align: center;
    font-size: 32px;
    border: 1px solid transparent;
    color: var(--text);
    border-radius: 4px;
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    background: var(--custom-input-bg-color);

    &:focus {
      color: var(--text);
      border: 1px solid #48bb8b;
      background-color: var(--active-background);
    }
  }

  .gender-icon {
    font-size: 30px;
    text-align: center;
    margin: auto;
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-right: 10px;
    color: white;
    &.male {
      background-color: #2da9d8;
    }
    &.female {
      background-color: #b73477;
    }
    &.ns {
      font-size: 40px;
      --g-red: #d04b36;
      --g-orange: #e36511;
      --g-yellow: #ffba00;
      --g-green: #00b180;
      --g-blue: #147aab;
      --g-indigo: #675997;
      background-image: linear-gradient(
        var(--g-red) 0%,
        var(--g-red) 16.6666%,
        var(--g-orange) 16.6666%,
        var(--g-orange) 33.333%,
        var(--g-yellow) 33.333%,
        var(--g-yellow) 50%,
        var(--g-green) 50%,
        var(--g-green) 66.6666%,
        var(--g-blue) 66.6666%,
        var(--g-blue) 83.3333%,
        var(--g-indigo) 83.3333%,
        var(--g-indigo) 100%
      );

      // background: linear-gradient(
      //   180deg,
      //   #fe0000 16.66%,
      //   #fd8c00 16.66%,
      //   33.32%,
      //   #ffe500 33.32%,
      //   49.98%,
      //   #119f0b 49.98%,
      //   66.64%,
      //   #0644b3 66.64%,
      //   83.3%,
      //   #c22edc 83.3%
      // );
    }
  }

  .total-counter {
    width: 150px;
    padding: 4px 0;

    text-align: center;
    box-shadow: 1px 1px 2px -1px #ffffff26, inset 1px 1px 3px 0px #00000026;
    border: 1px solid transparent;
    background: var(--input-background);
    color: var(--text);
    font-size: 26px;
    border-radius: 4px;
    display: inline-block;
    vertical-align: middle;
    appearance: none;
    -webkit-appearance: none;
    outline: none;

    .label {
      font-size: 12px;
    }
    .total-container {
      font-size: 35px;
    }
  }
}

.input-wrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin: 1em 0;
  position: relative;
  width: 100%;
}

.order-total.col {
  margin-top: 1em !important;
  margin-bottom: 0.5em !important;
}
.guestlist-submit {
  margin-top: 0.5em !important;
  ::v-deep .formulate-input-wrapper > .formulate-input-element--submit {
    justify-content: center;
  }
  ::v-deep .formulate-input[data-classification="button"] button {
    margin: auto;
  }
}
span.error {
  color: red !important;
}
.footer {
  display: flex;
  margin-top: 16px;
  justify-content: space-between;

  @media screen and (max-width: 470px) {
    flex-wrap: wrap;
    .powered-logo,
    button {
      margin: auto;
    }
    .powered-logo {
      margin-top: 16px;
    }
  }
}
</style>
