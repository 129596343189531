<template>
  <div class="section-container">
    <FormulateInput
      type="multiSelect"
      label="Section/Table"
      placeholder="Select one or multiple sections"
      name="paid_sections"
      :options="filteredPaidSections"
      @input="updateSections"
      validation="required"
    />
  </div>
</template>

<script>
export default {
  name: "guestlist-padi-sections",
  props: {
    paidSections: Array
  },
  computed: {
    filteredPaidSections() {
      let filtered = [];
      this.paidSections.forEach(section => {
        filtered.push({
          label: `${section.name} - $${section.price}`,
          value: section._id
        });
      });
      return filtered;
    }
  },
  methods: {
    incrementItem(item) {
      item.quantity++;
    },
    decrementItem(item) {
      if (item.quantity > 0) {
        item.quantity--;
      }
    },
    updateSections(sections) {
      sections.forEach(selected => {
        if (selected) {
          let item = this.paidSections.find(section => {
            return section._id === selected;
          });
          //  if()
        }
      });
    }
    // getCartItem(cart, tier, venueAccessDate, venueAccessTime) {
    //   if (venueAccessTime) {
    //     return cart.find(t => t.venueAccessTimeId === venueAccessTime.id);
    //   }
    //   if (venueAccessDate) {
    //     return cart.find(t => t.venueAccessDateId === venueAccessDate.id);
    //   }
    //   return cart.find(t => t.tierId === tier.saleDateId);
    // },
    // incrementItem(tier, index, venueAccessDate, venueAccessTime) {
    //   const eventURL = this.$route.params.event;
    //   const cart = this.$store.state.guestlist_cart;
    //   const item = getCartItem(cart, tier, venueAccessDate, venueAccessTime);
    //   const ccfee = tier.absorb ? 0 : tier.ccfee;
    //   const dfee = tier.absorb ? 0 : tier.dfee;
    //   let name = tier.name;
    //   if (venueAccessTime) {
    //     name +=
    //       " " + dayjs(venueAccessTime.start).format("(MMM DD, YYYY h:mm A)");
    //   } else if (venueAccessDate) {
    //     name += " " + dayjs(venueAccessDate.start).format("(MMM DD, YYYY)");
    //   }
    //   this.updateCart(item._id, item.quantity);
    //   // let limit = tierSaleDate.purchase_limit
    //   //   ? tierSaleDate.max_purchase
    //   //   : getMaxQuantity(tier, venueAccessDate, venueAccessTime);
    //   this.$forceUpdate();
    // },
    // decrementItem(tier, index, venueAccessDate, venueAccessTime) {
    //   const cart = this.$store.state.guestlist_cart;
    //   const item = getCartItem(cart, tier, venueAccessDate, venueAccessTime);
    //   let tierSaleDate = tier.saleDates.find(x => tier.saleDateId === x.id);
    //   let min_purchase = tierSaleDate.purchase_limit
    //     ? tierSaleDate.min_purchase
    //     : 1;
    //   this.inputArr[index] =
    //     parseInt(this.inputArr[index]) - min_purchase < min_purchase
    //       ? 0
    //       : parseInt(this.inputArr[index]) - min_purchase;
    //   if (item) {
    //     item.quantity =
    //       item.quantity - min_purchase < min_purchase
    //         ? 0
    //         : item.quantity - min_purchase;
    //     if (item.quantity <= 0) {
    //       cart.splice(cart.indexOf(item), 1);
    //     }
    //     this.updateCart(item._id, item.quantity);
    //   }
    //   this.$forceUpdate();
    // },
    // updateCart(productId, quantity) {
    //   this.$store
    //     .dispatch("updateCart", { productId, quantity })
    //     .then(() => {
    //       console.log("Cart updated successfully");
    //     })
    //     .catch(error => {
    //       console.error(error);
    //     });
    // }
  }
};
</script>

<style lang="less" scoped>
</style>